<template>
  <div class="course-arrange">
    <div v-for="item in list" :key="item.id">
      <p>{{item.chapterName}}</p>
      <a-button @click="videoUrl(item.videoType,item.videoUrl,item.id)">立即观看</a-button>
      <a-modal v-model="visible" :ok-button-props="{ style: { display: 'none' } }" cancelText="关闭" @cancel="closeVideo">
        <v-video-player controls :options="videoOptions" width="100%" height="600px" ref="videoPlayer"/>
      </a-modal>
    </div>
  </div>
</template>
<script>

import { courseSignUp } from "@/api/activity";
import { mapGetters } from "vuex";
import VVideoPlayer from '@/components/VVideoPlayer';
export default {
  props:['list','id','vodStatus','acticityIds'],
  computed: mapGetters(["userInfo","settings"]),
  data(){
    return {
      visible: false,
      src:"",
      videoOptions:{
        autoplay: true,
        loop: true,
        muted: true,
        bigPlayButton: false,
        controls: true, // 是否显示控制条
        controlBar: {children: [
          {name: 'playToggle'}, // 播放按钮
          {name: 'currentTimeDisplay'}, // 当前已播放时间
          {name: 'progressControl'}, // 播放进度条
          {name: 'durationDisplay'}, // 总时间
          { // 倍数播放
            name: 'playbackRateMenuButton',
            'playbackRates': [0.5, 1, 1.5, 2, 2.5]
          },
          {
            name: 'volumePanel', // 音量控制
            inline: false, // 不使用水平方式
          },
          {name: 'FullscreenToggle'} // 全屏
        ]
      },
        sources: [
          {
            src: `/`,
            type: "video/mp4",
          },
        ],
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      }
    }
  },
  components:{VVideoPlayer},
  methods:{
    videoUrl(type,url,id){
      // if (!this.userInfo) {
      //   this.$message.warning("您还未登录！");
      //   this.$router.push('/login');
      //   return;
      // }
      if(type==2){
        let data = {
          chapterId:id,
          courseId:this.id
        }
        courseSignUp(data).then((res)=>{})
        window.open(`${url}`,'_blank')
      }
      if(type == 1){

        //验证是否报名活动
        const { settings } = this;
        this.videoOptions['sources'][0]['src']=`${settings.OBS_PREFIX}/`+url;
        this.visible=true;
      }

    },
    closeVideo(){
      this.$ref.videoPlayer.beforeDestroy();
    }
  }
}
</script>

<style lang="scss" scoped>
.course-arrange{
  padding: 0 24px;
  >div{
    height: 88px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    &:last-child{
      border-bottom: 0;
    }
    >span{
      color: #EA0B06;
      width: 136px;
    }
    >p{
      flex: 1;
      margin-right: 24px;
    }
  }
}


</style>
